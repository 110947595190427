<template>
  <div>
    <a-modal
      width="500px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="退卡入库"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <p>确定对此项目进行退卡入库吗？操作后将无法修改，卡将退回库存</p>

        <a-form-item :label="`备注`" style="margin-top: 20px">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                {required: true, message: '请填写备注'},
                { max: 200, message: '最多200个字符' },
              ]
            }]"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { backToInventoryAgentPurchaseItem } from '@/api/agent_purchase_item'
export default {
  name: 'BackToInventoryAgentPurchaseItem',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      remark: '',
      submitting: false,
      form: this.$form.createForm(this, { name: 'back_to_inventory' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          backToInventoryAgentPurchaseItem(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
