import request from '@/utils/request'

// 查询采购项目划拨需要的信息
export function findAgentPurchaseItemAllocationInfo(agentPurchaseItemId) {
  return request({
    url: `/agent_purchase_items/${agentPurchaseItemId}/allocation_info`,
    method: 'get'
  })
}

// 划拨给分销商(采购划拨)
export function allocateCardsToTopAgent(agentPurchaseItemId, data) {
  return request({
    url: `/agent_purchase_items/${agentPurchaseItemId}/allocate`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 重新划拨给分销商(采购划拨)
export function reAllocateCardsToTopAgent(data) {
  return request({
    url: `/agent_purchase_items/re_allocate`,
    method: 'post',
    data
  })
}

// 查询单个采购项目
export function findAgentPurchaseItem(id) {
  return request({
    url: `/agent_purchase_items/${id}`,
    method: 'get'
  })
}

// 配置项目
export function configureAgentPurchaseItem(agentPurchaseItemId, data) {
  return request({
    url: `/agent_purchase_items/${agentPurchaseItemId}/configure`,
    method: 'post',
    data
  })
}

// 失效项目
export function invalidAgentPurchaseItem(agentPurchaseItemId, data) {
  return request({
    url: `/agent_purchase_items/${agentPurchaseItemId}/invalid`,
    method: 'post',
    data
  })
}

// 查询单个采购项目配置表单参数
export function findAgentPurchaseItemConfigureForm(id) {
  return request({
    url: `/agent_purchase_items/${id}/configure_form`,
    method: 'get'
  })
}

// 查询单个采购项目划拨记录
export function findAgentPurchaseItemAllocationRecords(id) {
  return request({
    url: `/agent_purchase_items/${id}/allocation_records`,
    method: 'get'
  })
}

// 计算沉默期截止时间
export function calculateAgentPurchaseItemSilentPeriodEndDate(id, data) {
  return request({
    url: `/agent_purchase_items/${id}/calculate_silent_period_end_date`,
    method: 'post',
    data
  })
}

// 计算测试期截止时间
export function calculateAgentPurchaseItemTestPeriodEndDate(id, data) {
  return request({
    url: `/agent_purchase_items/${id}/calculate_test_period_end_date`,
    method: 'post',
    data
  })
}

// 退卡入库项目
export function backToInventoryAgentPurchaseItem(agentPurchaseItemId, data) {
  return request({
    url: `/agent_purchase_items/${agentPurchaseItemId}/back_to_inventory`,
    method: 'post',
    data
  })
}
